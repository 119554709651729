import React, { useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const AvisoLegal = () => {

   useEffect(() => {
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = 'https://consent.cookiebot.com/f730530e-09ef-49ae-a7d0-74276b765a4a/cd.js';
      script.type = 'text/javascript';
      script.async = true;
  
      const cookieDeclarationDiv = document.getElementById('CookieDeclaration');
      cookieDeclarationDiv.appendChild(script);
  
      return () => {
        // Limpiar el script si el componente se desmonta
        cookieDeclarationDiv.removeChild(script);
      };
   }, []);

   const { t } = useTranslation(); 
   
   return (
      <div>
         <Header />
         <div className="espacioHeader"></div>
         <div className="simple-page" id="cookies">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="politica-cookies-titulo" /></h1>
                     <Trans i18nKey="politica-cookies-texto" components={{
                        br: <br />,
                        h2: <h2 />,
                        h4: <h4 />,
                        p: <p />,
                        a: <a />,
                        b: <b />,
                        strong: <strong />,
                        ul: <ul />,
                        li: <li />,
                        em: <em />,
                     }} />
                     <div id="CookieDeclaration"></div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
};

export default AvisoLegal;