import React, { useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const Igualdad = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Header />
            <div className="espacioHeader"></div>
            <div className="simple-page" id="plan-igualdad">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>QUESOS VEGA SOTUELAMOS: Comprometidos con la Igualdad</h1>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <img src="/images/foto-igualdad.jpg" className="w-100 mb-5" alt="mujeres felices en una escalera abrazadas y sonriendo" />
                                </div>
                            </div>
                            <p>En <strong>QUESOS VEGA SOTUELAMOS</strong>, asumimos un firme compromiso con la promoción de políticas que garanticen la igualdad de trato y oportunidades para todas las personas. Trabajamos activamente para construir un entorno inclusivo y eliminar cualquier forma de discriminación, especialmente aquella que pueda surgir de manera directa o indirecta por cuestiones de género. Por ello, hemos desarrollado e implementado nuestro <strong>Plan de Igualdad</strong>, diseñado para garantizar que cada persona pueda desarrollar su carrera profesional en condiciones de equidad y respeto mutuo.</p>

                            <h2 className="mt-4">Un Plan de Igualdad con visión integral</h2>

                            <p>Nuestro Plan de Igualdad se estructura en torno a seis áreas clave: la equidad salarial, la adecuación de nuestras instalaciones, la conciliación entre la vida personal y profesional, la igualdad en el acceso a oportunidades laborales, las relaciones laborales justas y el desarrollo profesional. En cada uno de estos ejes, hemos definido objetivos específicos y medidas concretas para alcanzarlos.</p>

                            <p>Con estas acciones, <strong>QUESOS VEGA SOTUELAMOS</strong> aspira a ser un referente en igualdad dentro de la industria alimentaria, un sector históricamente marcado por desequilibrios de género.</p>

                            <h2 className="mt-4">Acciones destacadas en pro de la igualdad</h2>

                            <p><strong>Promoción de la igualdad en el empleo</strong></p>
                            <p>En <strong>QUESOS VEGA SOTUELAMOS</strong>, fomentamos la igualdad en nuestras ofertas de empleo y priorizamos la contratación del género menos representado en nuestro sector. Además, trabajamos para hacer más accesibles nuestras vacantes mediante campañas dirigidas a ampliar la participación de mujeres, impulsando también la conversión de contratos a tiempo parcial en contratos a jornada completa.</p>

                            <p><strong>Instalaciones inclusivas</strong></p>
                            <p>Nuestras instalaciones están diseñadas para garantizar la comodidad y accesibilidad de todos nuestros empleados, asegurando espacios adecuados tanto en vestuarios como en áreas comunes para hombres y mujeres.</p>

                            <p><strong>Supervisión continua de la igualdad</strong></p>
                            <p>Realizamos un registro detallado de salarios y permisos relacionados con la conciliación familiar y laboral, con el objetivo de garantizar condiciones justas y equitativas para todos. También promovemos la flexibilidad horaria, facilitando cambios de turno cuando sea necesario.</p>

                            <p><strong>Formación y sensibilización en igualdad</strong></p>
                            <p>La formación de nuestra plantilla es clave en nuestro compromiso. Por ello, ofrecemos capacitación específica en temas de igualdad y prevención del acoso a directivos, mandos intermedios y personal de Recursos Humanos. También organizamos jornadas de sensibilización para todo el equipo.</p>

                            <p>Nos aseguramos de que cualquier empleado que se encuentre en situación de permiso pueda acceder a las formaciones al reincorporarse, garantizando así la igualdad de oportunidades en el aprendizaje.</p>

                            <p><strong>Comunicación interna y externa</strong></p>
                            <p>La comunicación es un pilar fundamental de nuestro Plan de Igualdad. Mantendremos un diálogo constante entre el Comité de Igualdad y nuestros trabajadores, garantizando que todos estén informados sobre los derechos, permisos y medidas disponibles.</p>

                            <p>Además, en el marco de nuestro compromiso, lanzaremos una campaña para compartir con la sociedad, nuestros clientes y colaboradores, las acciones que estamos llevando a cabo en pro de la igualdad.</p>

                            <p><strong>Medidas específicas en defensa de la igualdad</strong></p>
                            <p>Entre las acciones ya implementadas, hemos desarrollado un protocolo para prevenir y actuar frente al acoso laboral, sexual y por razones de género. Asimismo, ofrecemos apoyo específico a trabajadoras víctimas de violencia de género, adaptando las condiciones laborales para su bienestar.</p>

                            <p>En <strong>QUESOS VEGA SOTUELAMOS</strong>, creemos que la igualdad no es solo un objetivo laboral, sino un principio que debe reflejarse en todos los ámbitos de la sociedad. Con nuestras iniciativas, seguimos avanzando para derribar barreras y construir un futuro más equitativo para todos.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Igualdad;