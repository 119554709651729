import React, {useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const AvisoLegal = () => {
   const { t } = useTranslation(); 
   
   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="simple-page">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="aviso-legal-titulo" /></h1>
                     <Trans i18nKey="aviso-legal-texto" components={{
                        br: <br />,
                        h2: <h2 />,
                        h4: <h4 />,
                        p: <p />,
                        a: <a />,
                        b: <b />,
                        strong: <strong />,
                        ul: <ul />,
                        li: <li />,
                        em: <em />,
                     }} />
                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default AvisoLegal;